import { ElementHTML } from '@helpers/helper.rendering'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImage } from 'gatsby-plugin-image'
import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast, Toaster } from 'react-hot-toast'
import { RealEstateDataFooterData } from 'types'
import { useCreateFormMutation as useSendContactFormMutationMediacore } from '@apis/mediacore/contact'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import InlineSVG from '@boilerplates/inlineSvg'

const Footer8 = (props: RealEstateDataFooterData) => {
  const {
    image_gatsby,
    global_data,
    titleElement,
    titleHexa,
    titles,
    backgroundHexa,
    socialMediaFooter,
  } = props
  const logo = global_data.logo
  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const captcha = global_data.pageConfig.keys.captcha
  const realEstateName = global_data.name

  const [sendContactFormMediacore] = useSendContactFormMutationMediacore()

  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )

  const getCurrentYear = () => new Date().getFullYear()

  const [disabled, setDisabled] = useState(true)
  const [data, setData] = useState({
    email: '',
  })

  const [token, setValueToken] = useState('')

  function onChange(value: any) {
    setValueToken(value)
  }

  const sendForm = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const tags = ['Sitio Web', 'Newsletter', realEstateName]
    sendContactFormMediacore({
      data: { ...data, tags, token: token },
      type: 'newsletter',
      site: global_data.id,
    })
    ;(document.getElementById('formNewsletter') as HTMLFormElement)?.reset()
    return toast.success('Te suscribiste a nuestro newsletter correctamente.')
  }

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const [isComponentMounted, setIsComponentMounted] = useState(false)

  useEffect(() => {
    setIsComponentMounted(true)
    setDisabled(!captcha ? false : true)
  }, [])

  return (
    isComponentMounted && (
      <footer
        id="footer"
        className="footer_8"
      >
        <Toaster
          toastOptions={{
            className: 'toast-className',
            style: {
              maxWidth: 'fit-content',
            },
          }}
        />
        <div style={backgroundHexa ? { backgroundColor: backgroundHexa } : {}}>
          <Container
            fluid
            className="py-5"
          >
            <Row>
              <Col
                className="text-start"
                lg={2}
              >
                {image_gatsby ? (
                  <GatsbyImage
                    objectFit="cover"
                    alt="logo-footer"
                    image={getImage(image_gatsby?.url!)!}
                    style={{ width: '150px' }}
                  />
                ) : (
                  <img
                    alt="logo-footer"
                    height={'32'}
                    width={'150'}
                    src={logo}
                  />
                )}
              </Col>
              <Col
                className="text-start me-lg-3"
                lg={2}
              >
                <ElementHTML
                  style={{ color: titleHexa }}
                  children={titles?.branch_office}
                  elementType={titleElement ?? 'h5'}
                  className="mb-3 mb-lg-5 mt-5 mt-lg-0"
                />
                {branch_office?.length > 0 &&
                  branch_office?.map((branch, index) => (
                    <div
                      key={index}
                      className="mb-4"
                    >
                      <p style={{ fontWeight: 'bold' }}>{branch.name}</p>
                      <p>{branch.address} </p>
                      <p>{branch.city}</p>
                    </div>
                  ))}
                <p style={{ fontWeight: 'bold' }}>{contact_data.phoneLine}</p>
                <p style={{ fontWeight: 'bold' }}>{contact_data.email}</p>
              </Col>
              <Col
                className="text-start me-lg-5"
                lg={1}
              >
                <ElementHTML
                  style={{ color: titleHexa }}
                  children={titles?.social}
                  elementType={titleElement ?? 'h5'}
                  className="mb-3 mb-lg-5 mt-5 mt-lg-0"
                />
                {socialMediaFooter && (
                  <div className="d-flex flex-row flex-md-column">
                    {socialMediaFooter.map((social, index) => (
                      <div
                        key={index}
                        className="mb-3 me-3"
                      >
                        <a
                          href={social.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {social.isSvg ? (
                            <InlineSVG
                              src={social.image ?? ''}
                              className="social-icon"
                            />
                          ) : (
                            <img
                              alt="logo-footer"
                              width={'25'}
                              src={social.image}
                            />
                          )}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </Col>
              <Col
                className="text-start me-lg-5"
                lg={3}
              >
                <ElementHTML
                  style={{ color: titleHexa }}
                  children={titles?.newsletter}
                  elementType={titleElement ?? 'h5'}
                  className="mb-3 mb-lg-5 mt-5 mt-lg-0"
                />
                <form
                  onSubmit={sendForm}
                  id="formNewsletter"
                >
                  <div className="input-group pe-5">
                    <input
                      type="email"
                      name="email"
                      required
                      placeholder="Suscribite ingresando tu e-mail"
                      className="form-control custom-form-control"
                      aria-label="Correo electrónico"
                      onChange={handleInputChange}
                    />
                    <button
                      type="submit"
                      className="position-absolute end-0 top-0 bottom-0"
                      aria-label="Enviar formulario"
                      style={{
                        border: 'none',
                        backgroundColor: 'inherit',
                      }}
                      disabled={disabled}
                    >
                      <i
                        className="icon-arrow-stick pe-3"
                        style={{ color: '#a5a5a5' }}
                      ></i>
                    </button>
                  </div>
                  <div className="d-flex">
                    {captcha ? (
                      <ReCAPTCHA
                        sitekey={captcha}
                        onChange={(value) => {
                          setDisabled(false)
                          onChange(value)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </Col>
              {(Boolean(INVESTOR_CONFIGURATION?.module) ||
                Boolean(OWNER_CONFIGURATION?.module)) && (
                <Col className="text-start ms-lg-5">
                  <ElementHTML
                    style={{ color: titleHexa }}
                    children={titles?.modules}
                    elementType={titleElement ?? 'h5'}
                    className="mb-3 mb-lg-5 mt-5 mt-lg-0"
                  />
                  {INVESTOR_CONFIGURATION?.module && (
                    <div>
                      <a
                        className="btn btn-investor border btn-primary mb-2 d-inline-block d-flex align-items-center justify-content-center"
                        target={'_blank'}
                        href={INVESTOR_CONFIGURATION.url_login}
                      >
                        <span>{INVESTOR_CONFIGURATION.text_cta || 'Acceso Inversores'}</span>
                        <i className={'icon-investment'} />
                      </a>
                    </div>
                  )}
                  {OWNER_CONFIGURATION?.module && (
                    <div className="d-block">
                      <a
                        className="btn btn-investor border btn-primary mb-2 d-block d-lg-flex justify-content-between"
                        target={'_blank'}
                        href={OWNER_CONFIGURATION.url_login}
                      >
                        <span>{OWNER_CONFIGURATION.text_cta || 'Acceso Propietarios'}</span>
                        <i className={'icon-investor-access'} />
                      </a>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </Container>
        </div>
        <div className="d-flex align-items-start text-center justify-content-center py-4">
          <p className="text-copyright d-block">
            Promoted by {''}
            <a
              target="_blank"
              href="https://mediahaus.com.ar"
              className="text-copyright"
            >
              MediaHaus
            </a>{' '}
            - Powered by{' '}
            <a
              target="_blank"
              href="https://mediacore.app/"
              className="text-copyright"
            >
              {' '}
              MediaCore
            </a>
            <span className="text-copyright d-block">
              Todos los derechos reservados ® {getCurrentYear()}
            </span>
          </p>
        </div>
      </footer>
    )
  )
}

export default Footer8
